.live {

    &__chat {
        position: relative;
        background-color: lightgray;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #212529;

        &-box {
            display: flex;
            flex-direction: column;
            width: 90%;
            max-height: 600px;
            height: 90%;
            margin: 0 auto;
            padding: 10px;
            background-color: #fff;
            overflow: scroll;
        }

        &-mes {
            position: relative;
            margin-top: 5px;
            padding: 5px 50px 10px 10px;
            border-radius: 15px;
            background-color: lightblue;
            float: left;
            overflow-wrap: anywhere;
        }

        &-name {
            margin: 0;
            font-weight: 700;
        }

        &-text {
            margin: 0;
        }

        &-form {
            width: 90%;
            height: 10%;
            max-height: 50px;
            background-color: #fff;
            margin: 10px auto;
            display: flex;
            justify-content: space-between;
        }

        &-input {
            width: 100%;
            padding: 5px;
            border: 1px solid black;
        }

        &-btn {
            border: 1px solid black;
        }

        &-time {
            position: absolute;
            top: 10px;
            right: 20px;
        }

        &-delete {
            position: absolute;
            bottom: 10px;
            right: 20px;
            background: none;
            border: none;
        }
    }

    &__agreement {
        padding: 10px;
    }
}

.embed-responsive {
    width: 100%;
}
